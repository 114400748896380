import { initReactI18next } from 'react-i18next';

import AsyncBackend from 'i18next-async-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import i18n from './config/i18n';

const resources = new Proxy(
  {},
  {
    get(_obj, lang) {
      return {
        siesta: () => import(`@superb-ai/i18n/${lang}/siesta.json`),
        curate: () => import(`@superb-ai/i18n/${lang}/curate.json`),
        model: () => import(`@superb-ai/i18n/${lang}/model.json`),
        sahara: () => import(`@superb-ai/i18n/${lang}/sahara.json`),
        common: () => import(`@superb-ai/i18n/${lang}/common.json`),
      };
    },
  },
);

export default {
  i18n,
  lng:
    typeof window === 'undefined' || process.env.NODE_ENV === 'test'
      ? 'cimode'
      : window.localStorage.i18nextLng || 'en',
  supportedLngs: ['en', 'ja', 'ko'],
  fallbackLng: 'en',
  ns: ['sahara', 'curate', 'model', 'siesta', 'common'],
  defaultNS: ['sahara', 'curate', 'model', 'siesta', 'common'],
  fallbackNS: ['sahara', 'curate', 'model', 'siesta', 'common'],
  debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  interpolation: {
    escapeValue: false,
  },
  backend: { resources },
  use: [AsyncBackend, LanguageDetector, initReactI18next],
  serializeConfig: false,
  returnEmptyString: false,
};
