import { ReactNode, useState } from 'react';

import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';

interface Props {
  state: unknown;
  children: ReactNode;
}

export const QueryProvider = ({ state, children }: Props) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      {/* {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />} */}
      <Hydrate state={state}>{children}</Hydrate>
    </QueryClientProvider>
  );
};

export const TEN_SECONDS = 1000 * 10;
export const TWENTY_SECONDS = 1000 * 20;
export const ONE_MINUTE = 1000 * 60 * 1;
export const FIVE_MINUTES = 1000 * 60 * 5;
