var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2024-09-13 develop ca7dd55"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

window.VERSION = process.env.SENTRY_RELEASE;
window.ENV = process.env.APP_ENV;

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: `https://${process.env.NEXT_PUBLIC_SENTRY_DSN}@sentry.io/1473360`,
  release: process.env.SENTRY_RELEASE,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: process.env.APP_ENV === 'prod' ? 0.2 : 1.0,
  environment: process.env.APP_ENV,
  ignoreErrors: ['Unauthorized', 'Exceeded limits', 'Not Authorized', 'Not Found', 'Duplicated'],
  beforeSend(event, hint) {
    const error = hint.originalException;

    if (error && error.message) {
      return messages.some(v => error.message.includes(v)) ? null : event;
    }

    if (event && typeof event === 'string') {
      return messages.some(v => event.includes(v)) ? null : event;
    }

    return event;
  },
});

const messages = [
  'S.replace is not a function',
  'currentTarget, isTrusted, target, type',
  "'IDBDatabase': The database connection is closing",
];
