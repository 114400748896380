import 'rsuite/dist/rsuite.min.css';
import '../components/elements/datePicker/DatePicker.css';
import '../menu/PageWithMenu.scss';
import '../index.css';

import { useEffect, useState } from 'react';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation, useTranslation } from 'next-i18next';

import nextI18NextConfig from '../../next-i18next.config';
import { QueryProvider } from '../queries';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const [render, setRender] = useState(typeof window !== 'undefined');
  // const res = test.getInitialProps();
  useEffect(() => {
    setRender(true);
  }, []);

  const { i18n } = useTranslation();
  const isInitI18n = Object.keys(i18n?.store?.data || {}).length !== 0;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=1100" />
        <title translate="no">Superb Platform</title>
      </Head>
      {render && isInitI18n && (
        <QueryProvider state={pageProps.dehydratedState}>
          {/* @ts-ignore: saa-680 */}
          <Component {...pageProps} />
        </QueryProvider>
      )}
    </>
  );
}

// @ts-ignore
export default appWithTranslation(MyApp, nextI18NextConfig as any) as any;
